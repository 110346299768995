.divh{
    background-color: rgb(241, 241, 238);
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 30px;
    padding: 30px;
    height: 74vh;

}
.titleh{
    padding-left: 35%;
}