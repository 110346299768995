.transactions-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  overflow-y: scroll;

}

.transactions-column {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 2rem;
  margin: 0 0 .5rem 0;
  border-bottom: 1px solid hsl(0, 0%, 95%);
}

.transactions-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 4px;
  color: var(--text-light);
  transition: all ease-in-out .1s;
}

.trans-seller {
  font-size: 1rem;
  font-weight: 500;
}

.trans-date {
  font-size: .8rem;
  font-weight: 100;
}

.trans-product {
  text-transform: capitalize;
}

.transactions-entry:hover {
  box-shadow: 0 .5px 2px hsl(0, 0%, 90%);
  color: var(--text-dark);
  transform: scale(102%);
}

@media (max-width: 1150px) {
  .transactions-table {
    padding: 0;
  }
}