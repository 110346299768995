.profile {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width:100%;
  max-width: 1400px;
  padding: 0 2rem;
  margin: 1rem auto;
  gap: 1rem;
}

.profile-title {
  grid-area: 1 / 1 / span 1 / span 12;
  font-size: 3rem;
  font-weight: 300;
  padding: .5rem 0;
}

.profile-basic-info-card {
  grid-area: 2 / 1 / span 4 / span 12;
}

.profile-costumer-info-card {
  grid-area: 6 / 1 / span 7 / span 12;
}

.profile-basic-info,
.profile-costumer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.profile-costumer-info {
  width: 300px;
  max-width: 80%;
}

.profile-info-field {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.profile-info-field .input-wrapper {
  width: 100%;
}

.profile-btns {
  display: flex;
  gap: 1rem;
}

.profile-edit-btn {
  border: none;
  font-size: 1rem;
  background-color: #00000000;
  color: var(--light-blue);
  height: 1.5rem;
  padding: 0 8px;
}

@media (min-width:1200px) {
  .profile-basic-info-card {
    grid-area: 2 / 1 / span 7 / 5;
  }
  
  .profile-costumer-info-card {
    grid-area: 2 / 5 / span 7 / 13;
  }

  .profile-costumer-info {
    width: 400px;
    max-width: 50%;
  }
}