.vp {
  height: 100%;
  width: 100%;
}

.pay-bar {
  background-color: var(--bg-secondary);
  display: flex;
  justify-content: center;
  padding: .5rem;
}

.container {
  height: 80%;
}

.pay-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 1rem;
  text-align: center;
  gap: 1rem;
}

.pay-btn {
  border: none;
  width: 200px;
}