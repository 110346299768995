.dash-paper {
  position: relative;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px hsl(0, 0%, 70%);
  background-color: #fff;
}
.dash-inner-card {
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dash-subtitle {
  font-size: 1.5rem;
  position: absolute;
  top: .5rem;
  background-color: #fff;
  padding: 0 2rem;
}