.home {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-title {
  font-size: 3rem;
  align-self: end;
  padding: 2rem 2rem 0 2rem;
}

.home-title :last-child {
  padding-left: 0.3em;
}

.home-text {
  padding: 2rem 2rem 0 3rem;
  font-size: 1rem;
  font-weight: 200;
  color: var(--text-light);
}

.home-image {
  width: 100vw;
  height: auto;
  align-self: end;
  max-width: 800px;
}

@media (min-width: 800px) {
  .home {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 4fr;
  }

  .home-title {
    align-self: center;
    justify-self: end;
    font-size: 5rem;
    grid-area: 2 / 1 / span 1 / span 1;
  }

  .home-text {
    grid-area: 3 / 1 / span 1 / span 1;
    align-self: start;
    justify-self: end;
    max-width: 70%;
    padding: 2rem;
    font-size: 1.5rem;
  }

  .home-image {
    grid-area: 1 / 2 / 4 / 3;
    width: 80%;
    height: auto;
    align-self: center;
    justify-self: center;
    max-width: 800px;
    border-radius: 100%;
  }
}
