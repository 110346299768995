.dashboard {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 0 2rem;
  margin: 1rem auto;
  gap: 1rem;
}

.dash-title-wrapper {
  grid-area: 1 / 1 / span 2 / span 12;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.dash-title {
  font-weight: 300;
}

.dash-transactions {
  grid-area: 3 / 1 / span 8 / span 8;
}

.dash-pay-method {
  grid-area: 3 / 9 / span 4 / span 4;
}

.dash-pay-method .dash-inner-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.dash-empty-msg {
  align-self: center;
  justify-self: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
}

@media (max-width: 1150px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .dash-paper {
    width: 100%;
  }

  .dash-transactions {
    max-height: 50vh;
    max-height: 50svh;
  }
}
