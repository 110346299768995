.div{
    background-color: rgb(241, 241, 238);
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 30px;
    padding-bottom: 64px;
}
.div2{
    display: flex;
    flex-direction: row;
}
.text1{
    left:52px;
    top:30px;
    font-size: 20px;
    line-height: 30px;
}
.photo{
    border-radius: 50px;
    margin-left: 220px;
    position: relative;
    right: 80px;
    top: 70%;
    transform: translate(0%, 12%);
}