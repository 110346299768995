.nav-wrapper {
  background-color: var(--bg-secondary);
  box-shadow: 0px 5px 5px #00000025;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0 auto;
  height: 4rem;
  max-width: 1400px;
}

.logo {
  width: 10rem;
}

.menu-icon {
  padding: 0 1rem 0 0;
  color: var(--font-secondary);
  cursor: pointer;
}

.nav-menu-mobile {
  position: absolute;
  top: 3.8rem;
  bottom: 0;
  right: 0;
  left: 50%;
  flex-direction: column;
  padding: 10px 20px;
  background-color: var(--bg-secondary);
  transition: all 1s ease-in-out;
  z-index: 10;
}

.nav-menu-hide {
  transform: translateX(50vw);
}

.nav-menu-show {
  visibility: visible;
  transform: translateX(0vw);
}

.nav-links-desktop {
  display: flex;
  gap: 2rem;
}

.nav-links span {
  color: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0 0 .2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-menu-mobile .round-btns {
  width: 100%;
}

.btns-row {
  display: flex;
  gap: 1.5rem;
  padding: 0 2rem;
}

.btns-row .round-btns {
  width: 100px;
}

.nav-avatar-wrapper {
  padding: 0 20px 0 100px;
  position: relative;
}

.visible {
  display: block;
}
