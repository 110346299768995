.div{
    background-color: rgb(241, 241, 238);
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 30px;
}
.div2{
    display: flex;
    flex-direction: row;
}
.text1{
    position: relative;
    left:52px;
    top:30px;
    font-size: 20px;
}
.photo{
    position: relative;
}