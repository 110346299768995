.card-container {
  z-index: 1;
  padding: 10px;
}

.card-background {
  background: center url("../Assets/4._Developer.jpg");
  border-radius: 8px;
}

.card {
  height: 150px;
  width: 245px;
  padding: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  backdrop-filter: blur(2rem);
  -webkit-backdrop-filter: blur(2rem);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.card__top-info {
  display: flex;
  justify-content: space-between;
}
.card__owner-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card__shadow {
  position: absolute;
  width: 40rem;
  height: 40rem;
  margin: -30rem 0 0 -30rem;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
