.fake-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: aquamarine;
  height: 3rem;
  padding: 2rem 3rem;
}

.fake-main {
  padding: 0rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf9f6;
  height: 100vh;
  overflow: scroll;
}

.fake-title {
  padding: 2rem 0;
  font-size: 3rem;
  font-weight: 400;
}

.fake-prod-card {
  width: 300px;
  height: 300px;
  background-color: #fff;
  border-radius: 0.5rem;
}

.fake-inner-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .7rem;
  align-items: center;
}

.fake-btn {
  border: none;
  background-color: #ffffff00;
  color: rgb(17, 161, 113);
  font-size: 1.5rem;
}

.fake-items-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
